<template>
<div class="flex justify-center items-center">
  <form
    class="w-full"
    @submit.prevent="login()">
    <input type="submit" class="hidden"/>

    <vs-col class="sm:p-2 p-4">
      <vs-input
        v-model="email"
        class="w-full mt-0"
        v-validate="'required|email|min:5|max:35'"
        data-vv-validate-on="input"
        :name="$tc('$General.Email')"
        icon-no-border
        icon="icon icon-mail"
        icon-pack="feather"
        :label-placeholder="$tc('$General.Email')"
        :danger="emailHasErrors"
        :danger-text="emailErrorMsg"
        val-icon-danger="clear"
        @input="wrongEmailError = false"/>

      <vs-input
        v-model="password"
        class="w-full mt-10"
        data-vv-validate-on="input"
        v-validate="'required'"
        type="password"
        :name="$t('$General.Password')"
        icon-no-border
        icon="icon icon-lock"
        icon-pack="feather"
        :label-placeholder="$t('$General.Password')"
        :danger="passwordHasErrors"
        :danger-text="passwordErrorMsg"
        val-icon-danger="clear"
        @input="wrongPasswordError = false"/>

      <forgot-password-link/>

      <div class="w-full mt-5">
        <vs-button
          class="w-full"
          @click="login()" >{{ $t('LoginWithEmail') }}</vs-button>
      </div>

      <vs-divider class="my-6">{{ $t('$General.Or') | uppercase }}</vs-divider>

      <sign-in-google-button
        @click="redirectLoginGoogle">
        {{ $t('LoginWithGoogle') }}
      </sign-in-google-button>
    </vs-col>
  </form>
</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import configApi from '@/api/config';

// Components
import SignInGoogleButton from '@/views/pages/auth/components/SignInGoogleButton.vue';
import ForgotPasswordLink from '@/views/modules/_components/ForgotPasswordLink.vue';

/**
 * Component to login on the app
 *
 * @module views/pages/auth/TheAuthLogin
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {string} [email=''] - the email to login
 * @vue-data {string} [password=''] - the password to login
 * @vue-data {boolean} [wrongEmailError=false] - indicate if wrong email error exist
 * @vue-data {boolean} [wrongPasswordError=false] - indicate if wrong password error exist
 * @vue-data {string} [WrongEmailOrPasswordErrorMsg=''] - wrong email or password error msg
 * @vue-computed {boolean} emailHasErrors - indicate if email has errors
 * @vue-computed {string} emailErrorMsg - indicate the first email error
 * @vue-computed {boolean} passwordHasErrors - indicate if password has errors
 * @vue-computed {string} passwordErrorMsg - indicate the first password error
 * @vue-event {void} login - login and notify the user
 * @vue-event {void} redirectLoginGoogle - redirect to login with google
 * @vue-event {void} verifyQueryParamToken - verify token form query params
 * @vue-event {void} login - basic login
 */
export default {
  name: 'TheAuthLogin',
  i18n: {
    messages: {
      en: {
        LoginWithEmail: 'Log in with email',
        LoginWithGoogle: 'Log in with Google',
        GoogleLoginErrorNotifyTitle: 'Google sign-up',
        GoogleLoginErrorNotifyMsg: 'An error has occurred with the verification of your Google account',
      },
    },
  },
  components: {
    SignInGoogleButton,
    ForgotPasswordLink,
  },
  data() {
    return {
      email: '',
      password: '',

      wrongEmailError: false,
      wrongPasswordError: false,
      WrongEmailOrPasswordErrorMsg: '',
    };
  },
  computed: {
    ...mapGetters({
      getUserFlag: 'auth/getUserFlag',
    }),
    emailHasErrors() {
      return this.errors.has(this.$tc('$General.Email')) || this.wrongEmailError;
    },
    emailErrorMsg() {
      return this.errors.first(this.$tc('$General.Email')) || this.WrongEmailOrPasswordErrorMsg;
    },
    passwordHasErrors() {
      return this.errors.has(this.$t('$General.Password')) || this.wrongPasswordError;
    },
    passwordErrorMsg() {
      return this.errors.first(this.$t('$General.Password')) || this.WrongEmailOrPasswordErrorMsg;
    },
  },
  created() {
    this.verifyQueryParamToken();
  },
  methods: {
    ...mapActions({
      signIn: 'auth/login',
      verifyToken: 'auth/verifyToken',
    }),
    redirectLoginGoogle() {
      window.location.href = `${configApi.url}/auth/google/false/false`;
    },
    async verifyQueryParamToken() {
      if (this.$route.query.token && this.$route.query.refreshToken) {
        this.$vs.loading({ type: 'radius' });
        await this.verifyToken({
          token: this.$route.query.token,
          refreshToken: this.$route.query.refreshToken,
        });
        await this.$router.push({ name: 'home' }).catch(() => {});
        this.$vs.loading.close();

        if (this.getUserFlag('acceptTermsAndConditions')) {
          this.$showSuccessActionNotification({
            title: this.$t('$Auth.LoginAttempt'),
            text: this.$t('$Auth.SuccessfullyLoginMsg'),
          });
        }
      } else if (this.$route.query.authEmailVerificationSuccess) {
        this.$showSuccessActionNotification({
          title: this.$t('$Auth.$EmailAccountVerification.VerificationNotificationTitle'),
          text: this.$t('$Auth.$EmailAccountVerification.VerificationNotificationOkMsg'),
        });
      } else if (this.$route.query.authEmailVerificationError) {
        this.$emit('email-unverified-login-attempt', this.$route.query.email);

        this.$showGeneralErrorNotification({
          title: this.$t('$Auth.$EmailAccountVerification.VerificationNotificationTitle'),
          text: this.$t('$Auth.$EmailAccountVerification.InvalidLinkClickedErrorMsg'),
        });
      } else if (this.$route.query.authGoogleError) {
        this.$showGeneralErrorNotification({
          title: this.$t('GoogleLoginErrorNotifyTitle'),
          text: this.$t('GoogleLoginErrorNotifyMsg'),
        });
      }
    },
    async login() {
      const formIsValid = await this.$validator.validate();

      if (!formIsValid) {
        document.getElementsByName([this.$validator.errors.items[0].field])[0].focus();
        return;
      }

      this.$vs.loading({ type: 'radius' });

      try {
        const { data } = await this.signIn({
          email: this.email,
          password: this.password,
        });

        if (data.twoFactorAuthenticationActivate) {
          await this.$router.push({
            name: 'login-second-step',
            query: { tokenSecondStep: data.tokenSecondStep },
          }).catch(() => {});
          this.$vs.loading.close();
        } else {
          await this.$router.push({ name: 'home' }).catch(() => {});
          this.$vs.loading.close();

          this.$showSuccessActionNotification({
            title: this.$t('$Auth.LoginAttempt'),
            text: this.$t('$Auth.SuccessfullyLoginMsg'),
          });
        }
      } catch (e) {
        this.$vs.loading.close();

        if (e.response && e.response.data) {
          if (e.response.data.emailStatus === this.$enums.Users.Status.EMAIL_NOT_VERIFIED) {
            this.$emit('email-unverified-login-attempt', this.email);
          } else if (e.response.data.wrongEmail || e.response.data.wrongPassword) {
            this.wrongEmailError = e.response.data.wrongEmail || false;
            this.wrongPasswordError = e.response.data.wrongPassword || false;
            this.WrongEmailOrPasswordErrorMsg = e.response.data.message;
          } else {
            throw e;
          }
        } else {
          throw e;
        }
      }
    },
  },
};
</script>
